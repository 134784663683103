.design-phone {
    width: 377px;
    height: 648px;
    background-color: #ffffff;
    margin: 0 auto;
    box-shadow: 0 0 28px 0 #ccc;

    .phone-content {
        position: relative;

        .phone-top {
            height: 66px;
            position: relative;
            background-image: url('../image/phone-top.png');
            background-position: 50%;
            background-size: contain;
            background-repeat: no-repeat;

            .phone-title {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                text-align: center;
            }
        }

        .phone-top::before {
            content: "";
            z-index: 2;
        }

        .phone-main {
            height: 580px;
            overflow-y: auto;

            .module-item {
                display: block;
                overflow: hidden;
                position: relative;

                .delete-btn {
                    position: absolute;
                    right: 2px;
                    bottom: 2px;
                    z-index: 100;
                    display: none;
                }
            }

            .module-item::before {
                content: "";
                z-index: 2;
            }

            .module-item:hover::before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 2px dashed #00a0e9;
                cursor: move;
            }

            .module-item:hover>.delete-btn {
                display: block !important;
            }
        }

        /* 设置滚动条的样式 */
        .phone-main::-webkit-scrollbar {
            width: 6px;
        }

        /* 滚动槽 */
        .phone-main::-webkit-scrollbar-track {
            border-radius: 6px;
        }

        /* 滚动条滑块 */
        .phone-main::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: rgba(0, 0, 0, 0.1);
        }

        .selected {
            .delete-btn {
                display: block !important;
            }
        }

        .selected::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px dashed #00a0e9;
            cursor: move;
        }
    }

    .module-banner {
        .ant-carousel {
            .slick-slide {
                text-align: center;
                overflow: hidden;
            }

            .slick-arrow.control-arrow {
                width: 25px;
                height: 25px;
                font-size: 25px;
                color: #fff;
                opacity: 0.3;
                z-index: 2;
            }

            .control-arrow:before {
                display: none;
            }

            .control-arrow:hover {
                opacity: 0.5;
            }

            .slick-slide h3 {
                color: #fff;
            }

            .ant-image {
                height: auto !important;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

        }

        .control-arrow-left {
            left: 10px;
            z-index: 1;
        }

        .control-arrow-right {
            right: 10px;
        }
    }

    .module-image {
        width: 100%;
        text-align: center;
    }

    .module-window {
        .window-body {
            position: relative;

            .window-item {
                width: 50%;
                display: block;
                float: left;
                text-align: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .window-body::after {
            clear: both !important;
        }
    }

    .module-video {
        margin: 10px 0;
    }

    .module-article {
        margin: 10px 0;

        .article-item {
            background-color: #ffffff;

            .item-body {
                .title {
                    font-size: 17px;
                    color: #3b3b3b;
                    font-weight: 700;
                    -webkit-font-smoothing: antialiased;
                    font-weight: 700;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .author {
                    line-height: 1em;
                    font-size: 11px;
                    color: #ababab;
                    margin-top: 5px;

                    span {
                        display: inline;
                        vertical-align: middle;
                        margin-right: 6px;
                    }
                }
            }

            .cover-image {
                height: auto;
                max-height: 120px;
                padding-left: 10px;

                img {
                    width: 110px;
                    height: 70px;
                    object-fit: cover;
                }
            }

        }
    }

    .module-search {
        background-color: #f1f1f2;
        padding: 10px;
        border: none;

        .square {
            background: #fff;
            overflow: hidden;
        }

        .radius {
            border-radius: 5px;
            background: #fff;
            overflow: hidden;
        }

        .round {
            border-radius: 30px;
            background: #fff;
            overflow: hidden;
        }
    }

    .module-notice {
        .notice-body {
            padding: 6px 12px;

            .notice-icon {
                margin-right: 10px;
            }
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;