.design-editor {
    background-color: #ffffff;
    min-height: 648px;
    background-color: #f8f9fa;
    overflow: hidden;
    padding: 15px;

    .editor-body {
        .sub-title {
            text-align: center;
            font-size: 14px;
            color: #9797a1;
            margin-bottom: 15px;
            line-height: 20px;
        }

        .block-box {
            background-color: #f0f2f5;
            border-radius: 4px;
            padding: 10px;
            margin: 10px 0;

            .block-title {
                line-height: 30px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                .title-left {
                    font-size: 14px;
                    font-weight: 600;
                }

                .delete-item {
                    font-size: 12px;
                }
            }

            .block-item {
                display: flex;
                margin-bottom: 6px;

                .label {
                    margin-right: 20px;
                    font-size: 12px;
                }
            }



            .tips {
                margin-top: 6px;
                font-size: 12px;
                color: #9797a1;
            }

            .block-right {
                text-align: right;

                .color-select-code {
                    margin: 0 10px;
                    color: #9797a1;
                }

                .color-reset {
                    cursor: pointer;
                }
            }
        }

        .add-image-btn {
            cursor: pointer;
            border-radius: 4px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            color: #1890ff;
            width: 100%;
            border: 1px solid #ffffff;
            background-color: #ffffff;

            .add-image-btn-txt {
                display: inline-block;
                margin: 0 5px;
            }
        }

        .add-image-btn:hover {
            border: 1px solid #1890ff;
            transition: all 0.5s;
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;