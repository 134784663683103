.web-block {
  max-width: 1300px;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding-top: 40px;
  margin: 0 auto;
}
.web-block .block-line-div {
  position: relative;
}
.web-block .block-line-div .line-bar {
  position: absolute;
  left: 0;
  top: -40px;
  display: none;
  background-color: #3e3e3e;
  color: #f5f5f5;
  transition: .2s ease;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
}
.web-block .block-line-div .line-bar .bar-title {
  padding: 0 18px;
  background-color: #494949;
  cursor: default;
}
.web-block .block-line-div .line-bar .bar-item {
  padding: 0 18px;
  cursor: pointer;
}
.web-block .block-line-div .line-bar .bar-item .bar-item-text {
  display: inline-block;
  height: 40px;
}
.web-block .block-line-div .line-bar .bar-item:hover {
  background: #222;
  color: #fff;
}
.web-block .block-line-div:hover > .line-bar {
  display: flex;
}
.layout-layer {
  left: 20px;
  top: 20px;
  position: fixed;
}
.layout-layer .ant-modal-footer {
  padding: 0;
}
.layout-editor {
  min-height: 200px;
}
.layout-editor .color-select-code {
  margin-left: 10px;
}
.layout-editor-drag {
  cursor: move;
}
.layout-editor-title {
  margin: 0 120px;
  font-size: 14px;
}
.layout-editor-footer {
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-flex: 0;
}
.layout-editor-footer .layout-editor-footer-btn {
  font-size: 12px;
  display: flex;
  box-sizing: border-box;
  padding: 5px 13px;
  cursor: pointer;
  transition: .36s ease;
  color: #fff;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.layout-editor-footer .layout-editor-footer-ok {
  background-color: #46be8a;
}
.layout-editor-footer .layout-editor-footer-cancel {
  background-color: #ccd5db;
}
.drawer-container .ant-drawer-body {
  padding: 0;
}
