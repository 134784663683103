.design-editor {
  background-color: #ffffff;
  min-height: 648px;
  background-color: #f8f9fa;
  overflow: hidden;
  padding: 15px;
}
.design-editor .editor-body .sub-title {
  text-align: center;
  font-size: 14px;
  color: #9797a1;
  margin-bottom: 15px;
  line-height: 20px;
}
.design-editor .editor-body .block-box {
  background-color: #f0f2f5;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
}
.design-editor .editor-body .block-box .block-title {
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.design-editor .editor-body .block-box .block-title .title-left {
  font-size: 14px;
  font-weight: 600;
}
.design-editor .editor-body .block-box .block-title .delete-item {
  font-size: 12px;
}
.design-editor .editor-body .block-box .block-item {
  display: flex;
  margin-bottom: 6px;
}
.design-editor .editor-body .block-box .block-item .label {
  margin-right: 20px;
  font-size: 12px;
}
.design-editor .editor-body .block-box .tips {
  margin-top: 6px;
  font-size: 12px;
  color: #9797a1;
}
.design-editor .editor-body .block-box .block-right {
  text-align: right;
}
.design-editor .editor-body .block-box .block-right .color-select-code {
  margin: 0 10px;
  color: #9797a1;
}
.design-editor .editor-body .block-box .block-right .color-reset {
  cursor: pointer;
}
.design-editor .editor-body .add-image-btn {
  cursor: pointer;
  border-radius: 4px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #1890ff;
  width: 100%;
  border: 1px solid #ffffff;
  background-color: #ffffff;
}
.design-editor .editor-body .add-image-btn .add-image-btn-txt {
  display: inline-block;
  margin: 0 5px;
}
.design-editor .editor-body .add-image-btn:hover {
  border: 1px solid #1890ff;
  transition: all 0.5s;
}
