.block-body {
  min-height: 100px;
  position: relative;
  border: 2px solid #ffffff;
  height: 100%;
}
.block-body .block-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
  background-color: #3e3e3e;
  color: #f5f5f5;
  transition: .2s ease;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  z-index: 2;
}
.block-body .block-bar .bar-title {
  padding: 0 18px;
  background-color: #494949;
  cursor: default;
}
.block-body .block-bar .bar-item {
  padding: 0 18px;
  cursor: pointer;
}
.block-body .block-bar .bar-item .bar-item-text {
  display: inline-block;
  height: 40px;
}
.block-body .block-bar .bar-item:hover {
  background: #222;
  color: #fff;
}
.block-body:hover {
  border: 2px dashed #000;
}
.block-body:hover > .block-bar {
  display: flex;
}
