.design-phone {
  width: 377px;
  height: 648px;
  background-color: #ffffff;
  margin: 0 auto;
  box-shadow: 0 0 28px 0 #ccc;
}
.design-phone .phone-content {
  position: relative;
  /* 设置滚动条的样式 */
  /* 滚动槽 */
  /* 滚动条滑块 */
}
.design-phone .phone-content .phone-top {
  height: 66px;
  position: relative;
  background-image: url('../image/phone-top.png');
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.design-phone .phone-content .phone-top .phone-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
}
.design-phone .phone-content .phone-top::before {
  content: "";
  z-index: 2;
}
.design-phone .phone-content .phone-main {
  height: 580px;
  overflow-y: auto;
}
.design-phone .phone-content .phone-main .module-item {
  display: block;
  overflow: hidden;
  position: relative;
}
.design-phone .phone-content .phone-main .module-item .delete-btn {
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 100;
  display: none;
}
.design-phone .phone-content .phone-main .module-item::before {
  content: "";
  z-index: 2;
}
.design-phone .phone-content .phone-main .module-item:hover::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px dashed #00a0e9;
  cursor: move;
}
.design-phone .phone-content .phone-main .module-item:hover > .delete-btn {
  display: block !important;
}
.design-phone .phone-content .phone-main::-webkit-scrollbar {
  width: 6px;
}
.design-phone .phone-content .phone-main::-webkit-scrollbar-track {
  border-radius: 6px;
}
.design-phone .phone-content .phone-main::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);
}
.design-phone .phone-content .selected .delete-btn {
  display: block !important;
}
.design-phone .phone-content .selected::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px dashed #00a0e9;
  cursor: move;
}
.design-phone .module-banner .ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}
.design-phone .module-banner .ant-carousel .slick-arrow.control-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  opacity: 0.3;
  z-index: 2;
}
.design-phone .module-banner .ant-carousel .control-arrow:before {
  display: none;
}
.design-phone .module-banner .ant-carousel .control-arrow:hover {
  opacity: 0.5;
}
.design-phone .module-banner .ant-carousel .slick-slide h3 {
  color: #fff;
}
.design-phone .module-banner .ant-carousel .ant-image {
  height: auto !important;
}
.design-phone .module-banner .ant-carousel .ant-image img {
  height: 100%;
  object-fit: cover;
}
.design-phone .module-banner .control-arrow-left {
  left: 10px;
  z-index: 1;
}
.design-phone .module-banner .control-arrow-right {
  right: 10px;
}
.design-phone .module-image {
  width: 100%;
  text-align: center;
}
.design-phone .module-window .window-body {
  position: relative;
}
.design-phone .module-window .window-body .window-item {
  width: 50%;
  display: block;
  float: left;
  text-align: center;
}
.design-phone .module-window .window-body .window-item img {
  width: 100%;
  height: 100%;
}
.design-phone .module-window .window-body::after {
  clear: both !important;
}
.design-phone .module-video {
  margin: 10px 0;
}
.design-phone .module-article {
  margin: 10px 0;
}
.design-phone .module-article .article-item {
  background-color: #ffffff;
}
.design-phone .module-article .article-item .item-body .title {
  font-size: 17px;
  color: #3b3b3b;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.design-phone .module-article .article-item .item-body .author {
  line-height: 1em;
  font-size: 11px;
  color: #ababab;
  margin-top: 5px;
}
.design-phone .module-article .article-item .item-body .author span {
  display: inline;
  vertical-align: middle;
  margin-right: 6px;
}
.design-phone .module-article .article-item .cover-image {
  height: auto;
  max-height: 120px;
  padding-left: 10px;
}
.design-phone .module-article .article-item .cover-image img {
  width: 110px;
  height: 70px;
  object-fit: cover;
}
.design-phone .module-search {
  background-color: #f1f1f2;
  padding: 10px;
  border: none;
}
.design-phone .module-search .square {
  background: #fff;
  overflow: hidden;
}
.design-phone .module-search .radius {
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}
.design-phone .module-search .round {
  border-radius: 30px;
  background: #fff;
  overflow: hidden;
}
.design-phone .module-notice .notice-body {
  padding: 6px 12px;
}
.design-phone .module-notice .notice-body .notice-icon {
  margin-right: 10px;
}
