.editor-txt {
    cursor: pointer;
}

.full-modal {
    .ant-modal {
        max-width: 100%;
        top: 0;
        padding-bottom: 0;
        margin: 0;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh);
    }

    .ant-modal-body {
        flex: 1;
        overflow-y: scroll;
        padding: 0;
    }
}

.web-editor {
    .editor-module {
        position: absolute;
        top: 200px;
        left: 0;
        z-index: 999;

        .editor-bar {
            width: 200px;
        }
    }

    .editor-header {
        height: 60px;
        line-height: 60px;
        font-size: 24px;
        text-align: center;
        border-bottom: 1px solid #f2f2f2;
    }

    .editor-body {



        .body-content {
            width: 1400px;
            margin: 0 auto;
        }
    }

    .editor-footer {
        height: 100px;
        line-height: 100px;
        font-size: 14px;
        text-align: center;
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;