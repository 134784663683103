.design-comodule {
  background-color: #ffffff;
  width: 312px;
  min-height: 648px;
}
.design-comodule .module-item {
  text-align: center;
  color: #9797a1;
  width: 90px;
  height: 90px;
  padding: 15px 0;
}
.design-comodule .module-item .module-icon {
  font-size: 36px;
}
.design-comodule .module-item .module-name {
  margin-top: 10px;
}
.design-comodule .module-item:hover {
  background-color: #fafafa;
  cursor: default;
}
