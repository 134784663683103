.editor-txt {
  cursor: pointer;
}
.full-modal .ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}
.full-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}
.full-modal .ant-modal-body {
  flex: 1;
  overflow-y: scroll;
  padding: 0;
}
.web-editor .editor-module {
  position: absolute;
  top: 200px;
  left: 0;
  z-index: 999;
}
.web-editor .editor-module .editor-bar {
  width: 200px;
}
.web-editor .editor-header {
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}
.web-editor .editor-body .body-content {
  width: 1400px;
  margin: 0 auto;
}
.web-editor .editor-footer {
  height: 100px;
  line-height: 100px;
  font-size: 14px;
  text-align: center;
}
