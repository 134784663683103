.design-comodule {
    background-color: #ffffff;
    width: 312px;
    min-height: 648px;

    .module-item {
        text-align: center;
        color: #9797a1;
        width: 90px;
        height: 90px;
        padding: 15px 0;

        .module-icon {
            font-size: 36px;
        }

        .module-name {
            margin-top: 10px;
        }
    }

    .module-item:hover {
        background-color: #fafafa;
        cursor: default;
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;